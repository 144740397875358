<template>
    <div>
        <el-dialog :title="addOrEditModbusCommandTitle" :visible.sync="addOrEditModbusCommandVisible" width="900px"
                   v-if="addOrEditModbusCommandVisible" top="12vh" center :close-on-click-modal="false">
            <el-steps :active="active" :align-center="true">
                <el-step @click.native="stepChange(0)" :status="active0Status" title="基础信息"></el-step>
                <el-step @click.native="stepChange(1)" :status="active1Status" title="定时信息"></el-step>
                <el-step @click.native="stepChange(2)" :status="active2Status" title="寄存器信息"></el-step>
            </el-steps>
            <div style="height: 520px">
                <div v-show="active === 0" style="padding-top: 50px" key="base">
                    <el-form :model="baseForm" :rules="formRules" ref="baseForm" label-width="150px" :inline="true" size="mini">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="中文名称：" prop="commandNameCn">
                                    <el-input v-model="baseForm.commandNameCn" placeholder="请输入指令中文名称" autocomplete="off" class="optItem"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="英文名称：" prop="commandName">
                                    <el-input :disabled="option === 'edit'" v-model="baseForm.commandName" placeholder="请输入指令英文名称" autocomplete="off" class="optItem"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="指令类型：" prop="commandType">
                                    <el-select :disabled="option === 'edit'" v-model="baseForm.commandType" clearable
                                               placeholder="请选择指令类型" @change="commandTypeChange" class="optItem">
                                        <el-option
                                                v-for="item in commandTypeSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="功能码：" prop="functionCode">
                                    <el-select :disabled="option === 'edit'" v-model="baseForm.functionCode" clearable
                                               placeholder="请选择功能码" @change="handleFunctionCodeChangeEvent" class="optItem">
                                        <el-option
                                                v-for="item in functionCodeSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="校验方式：" prop="verification">
                                    <el-select v-model="baseForm.verification" clearable placeholder="请选择校验方式" class="optItem">
                                        <el-option
                                                v-for="item in verificationSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="校验码大小端：" prop="verifyEndian">
                                    <el-select v-model="baseForm.verifyEndian" clearable placeholder="请选择校验码大小端" class="optItem">
                                        <el-option
                                                v-for="item in verifyEndianSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="数据入库：" prop="autoSave">
                                    <el-select v-model="baseForm.autoSave" clearable placeholder="请选择数据入库" class="optItem">
                                        <el-option
                                                v-for="item in yesNoSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-form-item v-if="showBindReadCommand" label="绑定读指令：" >
                                <el-select v-model="baseForm.readCommandId" clearable placeholder="请选择绑定的读指令" class="optItem">
                                    <el-option
                                            v-for="item in readCommandSelect"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="备注：" prop="remark">
                                    <el-input v-model="baseForm.remark" autocomplete="off" type="textarea" :rows="3" class="textArea" maxlength="250"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div v-show="active === 1" style="padding-top: 50px" key="engine">
                    <el-form :model="scheduleForm" :rules="formRules" ref="scheduleForm" label-width="150px" :inline="true" size="mini">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="定时执行：" prop="enableTimer">
                                    <el-select v-model="scheduleForm.enableTimer" clearable placeholder="请选择是否定时执行" @change="enableTimerChange" class="optItem">
                                        <el-option
                                                v-for="item in yesNoSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="cron表达式：" prop="cron">
                                    <el-tooltip class="item" effect="dark" content="cron表达式执行频率不可超过每分钟执行一次" placement="top">
                                        <el-input v-model="scheduleForm.cron" placeholder="请输入cron表达式" autocomplete="off" class="optItem"/>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div v-show="active === 2" key="environment">
                    <div style="font-size: 15px">已选寄存器数量: {{selectRegisterCount}}</div>
                    <div style="font-size: 15px">已选寄存器字节长度: {{selectRegisterLength}}</div>
                    <el-table
                        ref="multipleTable"
                        size="mini"
                        border stripe :data="registerTableData"
                        :header-cell-style="{background:'#eef1f6',color:'#606266', textAlign: 'center'}"
                        max-height="490"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column type="selection" width="45" align="center"></el-table-column>
                        <el-table-column type="index" width="55px" label="序号" align="center"></el-table-column>
                        <el-table-column :label="addressLabelContent" width="100px" prop="address" align="center"></el-table-column>
                        <el-table-column label="读写模式" width="70px" prop="rwModel" align="center" :formatter="rwModelFormat"></el-table-column>
                        <el-table-column label="字段键名" prop="fieldEn" align="center"></el-table-column>
                        <el-table-column label="中文含义" prop="fieldCn" align="center"></el-table-column>
                        <el-table-column label="组名" prop="groupName" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="last" v-if="this.active > 0">上一步</el-button>
                <el-button type="primary" size="mini" @click="saveCheck" v-if="this.active === 2" :loading="addOrEditModbusCommandLoading">保存</el-button>
                <el-button type="primary" size="mini" @click="next" v-else>下一步</el-button>
                <el-button size="mini" @click="addOrEditModbusCommandVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

    import message from '@/utils/message';
    import {
        addCommandInfo,
        editCommandInfo,
        queryAllReadCommandInfoList,
        queryCommandInfoDetail
    } from '@/api/modbus/modbusCommand';
    import {queryList} from '@/api/modbus/modbusRegister';

    export default {
    name: 'modbus-command-operation',
    data() {

        let validateCron = (rule, value, callback) => {

            if(value){

                if (value.substr(0, 1) === ' ' || value.substr(0, 1) === '\t' || value.substr(0, 1) === '\n' ||
                    value.substr(0, 1) === '\s' || value.substr(0, 1) === '\f' || value.substr(0, 1) === '\r') {

                    callback(new Error('不要以空格、换行、换页等开头'));
                } else if (value.indexOf('  ') > -1) {

                    callback(new Error('不要有过多空格'));
                }
                // 拆除多余的空格
                value = value.split(' ').filter(el => el).join(' ');
                if (value.split(' ').length < 6) {

                    callback(new Error('cron表达式格式错误，请检查！'));
                }
                // 分离出前六
                let value6 = value;
                // 自定义第七
                let value7 = '';
                if(value.split(' ').length === 7){

                    value6 = value.substring(0, value.lastIndexOf(' '));
                    value7 = value.substring(value.lastIndexOf(' ') + 1)
                }
                let parser=require('cron-parser');
                try{
                    // 前六使用组件进行校验
                    parser.parseExpression(value6);
                    // 第七子使用自定义规则校验
                    if(value7){

                        let currentYear = Number(new Date().getFullYear());
                        // 单个年份
                        let res1 = /^\d{4}$/.test(value7);
                        // 多个年份
                        let res2 = /^\d{4}(,\d{4})+$/.test(value7);
                        // 区间年份
                        let res3 = /^\d{4}-\d{4}$/.test(value7);
                        // 间隔年份
                        let res4 = /^\d{4}\/[1-9]\d{0,2}$/.test(value7);
                        if(value7 === '*'){

                            callback();
                        }else if(res1){

                            if(Number(value7) >= currentYear){

                                callback();
                            }else{

                                callback(new Error('请注意不要设置当前年之前的年份！'));
                            }
                        }else if(res2){

                            let res2Val = value7.split(',');
                            let checkArr = Array.from(new Set(res2Val));
                            let errVal = res2Val.find(el => Number(el) < currentYear);
                            if(!errVal && checkArr.length === res2Val.length){

                                callback();
                            }else{
                                callback(new Error('请注意不要设置当前年之前的年份，并且不重复！'));
                            }
                        }else if (res3){

                            let res3Val = value7.split('-')
                            if(Number(res3Val[0]) >= currentYear && Number(res3Val[0]) < Number(res3Val[1])){

                                callback();
                            }else{

                                callback(new Error('请注意不要设置当前年之前的年份，并保持起始小于结束！'));
                            }
                        }else if(res4){

                            callback();
                        }else{

                            callback(new Error('cron表达式格式错误，请检查！'));
                        }
                    }else{

                        callback();
                    }
                } catch (e) {

                    callback(new Error('cron表达式格式错误，请检查！'));
                }
            } else {
                callback(new Error('请输入cron表达式'));
            }
        };
        return {

            pointInfoId: '',
            commandId: '',
            addressLabelContent: '',
            selectRegisterCount: 0,
            selectRegisterLength: 0,
            baseForm: {

                autoSave: '',
                commandNameCn: '',
                commandName: '',
                commandType: '',
                verification: '',
                verifyEndian: '',
                functionCode: '',
                readCommandId: '',
                remark: ''
            },
            scheduleForm: {

                enableTimer: '',
                cron: ''
            },
            addressList: [],
            addressNum: [],

            allRegisterTableData: [],
            registerTableData: [],

            formRules:{

                commandNameCn: [
                    {required: true, message: '请输入指令中文名称', trigger: ['blur', 'change']},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] }
                ],
                commandName: [
                    {required: true, message: '请输入指令英文名称', trigger: ['blur', 'change']},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] },
                    { pattern: /^(?!_)(?!.*?_$)[a-zA-Z0-9_]+$/, message: '英文字母数字和\'_\'，不能以\'_\'开始或结束', trigger: ['blur', 'change'] }
                ],
                commandType: [
                    {required: true, message: '请选择指令类型', trigger: ['blur', 'change']}
                ],
                verification: [
                    {required: true, message: '请选择校验方式', trigger: ['blur', 'change']}
                ],
                verifyEndian: [
                    {required: true, message: '请选择大小端', trigger: ['blur', 'change']}
                ],
                functionCode: [
                    {required: true, message: '请选择功能码', trigger: ['blur', 'change']}
                ],
                remark: [
                    {min: 0, max: 250, message: '最大长度250字符', trigger: ['blur', 'change']}
                ],
                enableTimer: [
                    {required: true, message: '请选是否定时执行', trigger: ['blur', 'change']}
                ],
                autoSave: [
                    {required: true, message: '请选数据入库', trigger: ['blur', 'change']}
                ],
                cron: [
                    {required: true, validator: validateCron, trigger: ['blur', 'change']}
                ]
            },
            option: '',
            addOrEditModbusCommandTitle:'',
            addOrEditModbusCommandVisible: false,
            addOrEditModbusCommandLoading: false,
            active: 0,
            // active步骤对应状态
            active0Status: 'process',
            active1Status: 'wait',
            active2Status: 'wait',

            commandTypeSelect: [
                {
                    value: 'READ',
                    label: '读'
                },
                {
                    value: 'WRITE',
                    label: '写'
                },
            ],
            verificationSelect: [
                {
                    value: 1,
                    label: 'CRC16-MODBUS'
                },
            ],
            verifyEndianSelect: [
                {
                    value: 'LITTLE_ENDIAN',
                    label: '小端'
                },
                {
                    value: 'BIG_ENDIAN',
                    label: '大端'
                },
            ],
            yesNoSelect: [
                {
                    value: 1,
                    label: '是'
                },
                {
                    value: 0,
                    label: '否'
                },
            ],
            functionCodeSelect: [
                {
                    value: '03',
                    label: '03(读取保持寄存器)'
                },
                {
                    value: '04',
                    label: '04(读取输入寄存器)'
                },
                {
                    value: '06',
                    label: '06(单写寄存器)'
                },
                {
                    value: '10',
                    label: '10(多写寄存器)'
                }
            ],
            readCommandSelect: [],
            showBindReadCommand: false
        }
    },
    methods: {

        addOpenInitPage(pointInfoId) {

            this.pointInfoId = pointInfoId;
            this.openAddPage(pointInfoId);
            this.initStatus();
        },
        editOpenInitPage(commandId, pointInfoId) {

            this.pointInfoId = pointInfoId;
            this.commandId = commandId;
            this.openEditPage(commandId);
            this.initStatus();
        },
        last() {

            this.active = this.active - 1;
            this.stepChange(this.active)
        },
        next() {

            if (this.active === 0) {

                this.$refs['baseForm'].validate((valid) => {

                    if (valid) {

                        this.active = this.active + 1;
                        this.active0Status = 'success'
                        this.active1Status = 'process'
                    }
                })
            } else if(this.active === 1) {

                this.$refs['scheduleForm'].validate((valid) => {

                    if (valid) {

                        this.active = this.active + 1;
                        this.active1Status = 'success'
                        this.active2Status = 'process'
                    }
                })
            }
        },
        // 打开添加页面
        openAddPage(pointInfoId) {

            this.addOrEditModbusCommandTitle = '新增指令';
            this.option = 'add';
            this.baseForm.commandType = 'READ';
            this.baseForm.verification = 1;
            this.baseForm.verifyEndian = 'LITTLE_ENDIAN';
            this.baseForm.functionCode = '03';
            this.baseForm.commandName = '';
            this.baseForm.commandNameCn = '';
            this.baseForm.remark = '';
            this.baseForm.autoSave = 1;

            this.scheduleForm.enableTimer = 1;
            this.scheduleForm.cron = '';

            this.addressLabelContent = '地址';
            this.selectRegisterLength = 0;
            this.selectRegisterCount = 0;

            queryList({pointInfoId: pointInfoId}).then((res) => {

                if (res.code === 200) {

                    this.allRegisterTableData = res.data;
                    // 默认展示读和读/写和保持寄存器
                    this.registerTableData = res.data.filter(function(item) {

                        return (item.rwModel === 1 || item.rwModel === 2) && item.registerType === 1;
                    });
                    if (this.registerTableData.length > 0) {

                        this.addressLabelContent += '(' + this.registerTableData[0].addressType + '进制)';
                    }
                }
            }).catch(err => { console.error(err) });
        },
        // 打开编辑页面
        openEditPage(commandId) {

            this.addOrEditModbusCommandTitle = '编辑指令';
            this.option = 'edit';
            this.addressLabelContent = '地址';
            this.selectRegisterLength = 0;
            this.selectRegisterCount = 0;
            let param = {

                id: commandId,
                showPointAllRegisterInfo: true,
            }
            queryCommandInfoDetail(param).then((res) => {

                if (res.code === 200) {

                    this.allRegisterTableData = res.data.registerInfoList;
                    this.registerTableData = res.data.registerInfoList;
                    for (let i = 0; i < this.registerTableData.length; i++) {

                        if (this.registerTableData[i].checked) {

                            this.selectRegisterLength += this.registerTableData[i].addressLength;
                            this.selectRegisterCount++;
                        }
                    }
                    this.baseForm.commandName = res.data.commandName;
                    this.baseForm.commandNameCn = res.data.commandNameCn;
                    this.baseForm.commandType = res.data.commandType;
                    this.baseForm.verification = res.data.verification;
                    this.baseForm.verifyEndian = res.data.verifyEndian;
                    this.baseForm.autoSave = res.data.autoSave;
                    this.baseForm.functionCode = res.data.functionCode;
                    this.baseForm.readCommandId = res.data.readCommandId;
                    this.baseForm.remark = res.data.remark;

                    this.scheduleForm.enableTimer = res.data.enableTimer;
                    this.scheduleForm.cron = res.data.cron;

                    this.resetRegisterTableData(this.baseForm.commandType, this.baseForm.functionCode);
                    this.registerTableData.map(item => {

                        this.$nextTick(() => {

                            this.$refs.multipleTable.toggleRowSelection(item, item.checked);
                        });
                    })
                    if (res.data.enableTimer === 1) {

                        this.formRules.cron = [{required: true, validator: this.validateCron, trigger: ['blur', 'change']}]
                    } else {

                        this.formRules.cron = [{required: false, validator: this.validateCron, trigger: ['blur', 'change']}]
                    }
                    if (this.registerTableData.length > 0) {

                        this.addressLabelContent += '(' +this.registerTableData[0].addressType + '进制)';
                    }
                }
            }).catch(err => { console.error(err)});
        },
        // 添加或修改
        handleAddOrEditDevice () {

            if (this.option === 'add') {

                this.addCommand();
            } else {

                this.editCommand();
            }
        },
        addCommand() {

            this.addOrEditModbusCommandLoading = true;
            let param = {
                ...this.baseForm,
                ...this.scheduleForm,
                pointInfoId: this.pointInfoId,
                addressList: this.addressList,
            }
            addCommandInfo(param).then((res) => {

                this.addOrEditModbusCommandLoading = false;
                if (res.code === 200) {

                    this.addOrEditModbusCommandVisible = false;
                    message.success('添加指令成功');
                    this.$emit('refreshSettingTree');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                console.error(err);
                this.addOrEditModbusCommandLoading = false;
            });
        },
        editCommand() {

            this.addOrEditModbusCommandLoading = true;
            let param = {
                ...this.baseForm,
                ...this.scheduleForm,
                id: this.commandId,
                addressList: this.addressList,
            }
            editCommandInfo(param).then((res) => {

                this.addOrEditModbusCommandLoading = false;
                if (res.code === 200) {

                    this.addOrEditModbusCommandVisible = false;
                    message.success('编辑指令成功');
                    this.$emit('queryDetail');
                    this.$emit('refreshParentSettingTree');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                console.error(err);
                this.addOrEditModbusCommandLoading = false;
            });
        },
        stepChange(value) {

            this.refreshStatus(value)
            switch(value){

                case 0:
                    this.active0Status = 'process'
                    break;
                case 1:
                    this.active1Status = 'process'
                    break;
                case 2:
                    this.active2Status = 'process'
                    break;
                default:
                    break;
            }
            this.active = value
        },
        refreshStatus(targetActive) {

            if (targetActive === 0) {

                this.active1Status = 'wait';
                this.active2Status = 'wait';
                return;
            }

            this.$refs['baseForm'].validate((valid) => {

                if (valid) {

                    this.active0Status = 'success';
                } else {

                    this.active0Status = 'error';
                }
            })

            if (targetActive === 1) {

                this.active2Status = 'wait'
                return;
            }

            this.$refs['scheduleForm'].validate((valid) => {

                if (valid) {

                    this.active1Status = 'success';
                } else {

                    this.active1Status = 'error';
                }
            })
        },
        saveCheck() {

            let flag = true;
            this.$refs['baseForm'].validate((valid) => {

                flag = valid;
            })
            if (!flag) {

                this.active0Status = 'process'
                this.active1Status = 'wait'
                this.active2Status = 'wait'
                this.active = 0
                return
            }
            this.$refs['scheduleForm'].validate((valid) => {

                flag = valid;
            })
            if (!flag) {

                this.active1Status = 'process'
                this.active2Status = 'wait'
                this.active = 1
                return
            }
            if (!this.addressList || this.addressList.length < 1) {

                message.warning('至少勾选一个寄存器！');
                return;
            }
            if (flag && this.active === 2) {
                // 新增或修改指令信息
                this.handleAddOrEditDevice();
            }
        },
        initStatus() {

            this.active = 0;
            this.addOrEditModbusCommandVisible = true;
            this.active0Status = 'process';
            this.active1Status = 'wait';
            this.active2Status = 'wait';
            this.readCommandSelect = [];
            // 查询所有的读指令
            let readCommandParam = {

                id: this.pointInfoId
            }
            queryAllReadCommandInfoList(readCommandParam).then((res) => {

                if (res.code === 200) {

                    res.data.forEach(item => {

                        this.readCommandSelect.push({

                            value: item.commandId,
                            label: item.commandNameCn
                        });
                    })
                }
            })
        },
        rwModelFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '读';
            } else if (cellValue === 2) {

                return '读/写';
            } else if (cellValue === 3) {

                return '写';
            } else {

                return '未知';
            }
        },
        addressTypeFormat(row, column, cellValue) {

            return cellValue + '进制'
        },
        handleSelectionChange(val) {

            this.addressList = [];
            this.addressNum = [];
            this.selectRegisterLength = 0;
            this.selectRegisterCount = 0;
            if (val && val.length > 0) {

                val.map(item => {

                    this.addressList.push(item.id);
                    this.addressNum.push(Number(item.address));
                    this.selectRegisterLength += item.addressLength;
                    this.selectRegisterCount++;
                })
            }
        },
        enableTimerChange(val) {

            if (val === 1) {

                this.formRules.cron = [{required: true, validator: this.validateCron, trigger: ['blur', 'change']}]
            } else {

                this.formRules.cron = [{required: false, validator: this.validateCron, trigger: ['blur', 'change']}]
            }
        },
        commandTypeChange(commandType) {

            if (commandType === 'READ') {

                this.showBindReadCommand = false;
            } else if (commandType === 'WRITE') {

                this.showBindReadCommand = true;
            }
            this.resetRegisterTableData(commandType, this.baseForm.functionCode);
        },
        handleFunctionCodeChangeEvent(functionCode) {

            this.resetRegisterTableData(this.baseForm.commandType, functionCode);
        },
        resetRegisterTableData(commandType, functionCode) {

            this.handleSelectionChange([]);
            let rwModel = [];
            if (commandType === 'READ') {

                rwModel = [1, 2];
            } else if (commandType === 'WRITE') {

                rwModel = [2, 3];
            }
            let registerType = null;
            if (functionCode === '03') {

                registerType = 1;
            } else if (functionCode === '04') {

                registerType = 2;
            }
            this.registerTableData = this.allRegisterTableData.filter(function(item) {

                return (rwModel.length === 0 || rwModel.includes(item.rwModel))
                    && (registerType === null || registerType === item.registerType);
            });
        },
        validateCron(rule, value, callback) {

            if(value){

                if (value.substr(0, 1) === ' ' || value.substr(0, 1) === '\t' || value.substr(0, 1) === '\n' ||
                    value.substr(0, 1) === '\s' || value.substr(0, 1) === '\f' || value.substr(0, 1) === '\r') {

                    callback(new Error('不要以空格、换行、换页等开头'));
                } else if (value.indexOf('  ') > -1) {

                    callback(new Error('不要有过多空格'));
                }
                // 拆除多余的空格
                value = value.split(' ').filter(el => el).join(' ');
                if (value.split(' ').length < 6) {

                    callback(new Error('cron表达式格式错误，请检查！'));
                }
                // 分离出前六
                let value6 = value;
                // 自定义第七
                let value7 = '';
                if(value.split(' ').length === 7){

                    value6 = value.substring(0, value.lastIndexOf(' '));
                    value7 = value.substring(value.lastIndexOf(' ') + 1)
                }
                let parser=require('cron-parser');
                try{
                    // 前六使用组件进行校验
                    parser.parseExpression(value6);
                    // 第七子使用自定义规则校验
                    if(value7){

                        let currentYear = Number(new Date().getFullYear());
                        // 单个年份
                        let res1 = /^\d{4}$/.test(value7);
                        // 多个年份
                        let res2 = /^\d{4}(,\d{4})+$/.test(value7);
                        // 区间年份
                        let res3 = /^\d{4}-\d{4}$/.test(value7);
                        // 间隔年份
                        let res4 = /^\d{4}\/[1-9]\d{0,2}$/.test(value7);
                        if(value7 === '*'){

                            callback();
                        }else if(res1){

                            if(Number(value7) >= currentYear){

                                callback();
                            }else{

                                callback(new Error('请注意不要设置当前年之前的年份！'));
                            }
                        }else if(res2){

                            let res2Val = value7.split(',');
                            let checkArr = Array.from(new Set(res2Val));
                            let errVal = res2Val.find(el => Number(el) < currentYear);
                            if(!errVal && checkArr.length === res2Val.length){

                                callback();
                            }else{
                                callback(new Error('请注意不要设置当前年之前的年份，并且不重复！'));
                            }
                        }else if (res3){

                            let res3Val = value7.split('-')
                            if(Number(res3Val[0]) >= currentYear && Number(res3Val[0]) < Number(res3Val[1])){

                                callback();
                            }else{

                                callback(new Error('请注意不要设置当前年之前的年份，并保持起始小于结束！'));
                            }
                        }else if(res4){

                            callback();
                        }else{

                            callback(new Error('cron表达式格式错误，请检查！'));
                        }
                    }else{

                        callback();
                    }
                } catch (e) {

                    callback(new Error('cron表达式格式错误，请检查！'));
                }
            } else {

                if (this.scheduleForm.enableTimer === 1) {

                    callback(new Error('请输入cron表达式'));
                } else {

                    callback();
                }
            }
        }
    },
}
</script>
<style scoped>

.optItem {

    width: 200px;
}
.textArea {

    width: 625px;
}
</style>
